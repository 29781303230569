import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import BOSSXVIIISquareLogo from '../../Assets/Images/BOSS XVIII Square Logo.png';
import nominationDOC from '../../Assets/Documents/Janssen Prize Nomination Form_September 2023.doc';
import "./Nominations.css";

const Nominations = (props) => {
    return (
        <div className="Nomination-Content">
            <Container fluid className="custom-minwidth">
                <section className="d-flex custom-nominee">
                    <div className="left-region w-50">
                        <div className="Prize2024 p-4">
                            <h4 className="px-5">
                                Nominations are currently closed.<br />
                                Check back in 2025 for<br />
                                information on the nomination <br/>
                                process for the 2026 prize.
                            </h4>
                            {/* <p>
                                Please fill out the <Link to={nominationDOC} className="historyLink"
                                    target="_blank"
                                    download="Janssen Prize Nomination Form_September 2023.doc">  attached </Link> Nomination Form and Submit to:
                                <br /> <Link to="mailto:secretariat@boss-symposium.org" className="historyLink"
                                    target="_blank"> secretariat@boss-symposium.org </Link>
                            </p> */}
                        </div>
                        <div className="Prize2024Bossxviii mt-3 p-4 text-center">
                            <img alt="BOSS XVIII Square Logo" className="BOSSXVIIISquareLogo_img" src={BOSSXVIIISquareLogo}></img>
                        </div>
                    </div>
                    <div className="right-region pt-4 ps-5 w-50">
                        <p><span className="p_header">Nomination criteria</span><br />The Janssen Prize for Creativity in Organic Synthesis is awarded every other year by Janssen Research & Development LLC in conjunction
                            with the <a target="_blank" className="historyLink" rel="noreferrer" href="https://www.boss-symposium.org/"> Belgian Organic Synthesis Symposium</a> to
                            an organic chemist who has made a significant contribution to the field of organic synthesis. The prize consists of a medal, a citation as well as 20.000 Euros.
                        </p>
                        <p><span className="p_header">Candidates:</span><br />Nomination of scientists of public or private universities,
                            government institutions or industry / pharmaceutical companies will be taken into consideration
                            based on the following criteria:
                        </p>
                            <ul>
                                <li>Candidates should be under the age of 50 years old when the jury makes its decision.</li>
                                <li>Members of the Prize Jury cannot be candidates, nor can they propose candidates.</li>
                                <li>Candidates cannot be suggested by Janssen employees.</li>
                                <li>The prize cannot be given twice to the same person.</li>
                            </ul>
                        <span className="p_header">Procedure:</span>
                            <ul>
                                <li>The nomination period for the 2024 prize is October 1 - December 31, 2023.</li>
                                <li>Fill out the <Link to={nominationDOC} className="historyLink"
                                    target="_blank"
                                    download="Janssen Prize Nomination Form_September 2023.doc"> attached </Link> nomination form and submit to <Link to="mailto:secretariat@boss-symposium.org" className="historyLink"
                                        target="_blank"> secretariat@boss-symposium.org </Link></li>
                                <li>The Janssen Prize will be announced at the <a target="_blank" className="historyLink" rel="noreferrer" href="https://www.boss-symposium.org/"> BOSS XVIII Symposium</a> (June 30 – July 5, 2024) and in the scientific press.</li>
                            </ul>
                        <span className="p_header">Jury:</span>
                            <ul>
                                <li>The Janssen Prize Jury is comprised of the President and seven members who are experts in the field of organic chemistry.</li>
                                <li>The President is appointed for six years and cannot be re-elected.</li>
                                <li>Two members of the jury are replaced every other year by two new members, taking into account seniority, diversity and geographical distribution.</li>
                                <li>At least one member of the jury is of Belgian nationality or is employed full time in Belgium.</li>
                                <li>The jury votes by mail. The jury’s award decision will be submitted to the Janssen R&D Discovery Chemistry leadership team, which will approve and formally announce the name of the award winner.</li>
                            </ul>
                    </div>
                </section>
            </Container>
        </div>
    )
};

export default Nominations;