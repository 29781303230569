import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import HistoryData from "./History.json";
import "./History.css";

export default function History() {

    const listItems = HistoryData.map(history =>
        <li key={history.id} className="py-4">
            <img className="history-images" src={require(`../../Assets/Images/${history.image}`)} alt={history.name}></img>
            <h6 className="pt-3"><b>{history.name}</b></h6>
            <p>
                {history.about1}<br />
                {history.about2}<br />
                {history.about3}
            </p>
        </li>
    );

    return (
        <div className="History-Content">
            <Container fluid className="custom-minwidth">
                <section className="d-flex">
                    <div className="custom-history-block pt-3 pe-5">
                        <h4 className="title-text">History</h4>
                        <p> The <span className="history-span"> Janssen Prize for Creativity in Organic Synthesis </span> was established in 1986 during the first Belgian Organic Synthesis Symposium (BOSS). This prestigious award has a 30+ year legacy of impressive winners and is bestowed every two years to a chemist under the age of 50 who has made a significant contribution to the field of organic synthesis. The recognition includes a trophy, a citation, and 20.000 Euros, along with the opportunity to deliver a lecture at the BOSS Meeting.</p>
                        <p>During the inaugural BOSS meeting, the first Janssen Prize was awarded in 1986 to Barry Sharpless (Nobel Laureate 2001).</p>
                        <p><span className="history-span">Honoring Dr. Paul Janssen</span><br/> Originally named the <span className="history-span"> Dr. Paul Janssen Prize for Creativity in Organic Synthesis</span>, the award honors the founder of Janssen Pharmaceutica – a scientist of exceptional vision and creativity. Dr. Janssen believed organic synthesis was pivotal in discovering and developing new therapeutic agents.</p>
                        <p><span className="history-span">From small rings to BOSS </span><br/> The idea of organizing symposia focused on organic synthesis originated during discussions in Leuven and then in Louvain-la-Neuve by <span className="history-span"> José Jaz </span>and<span className="history-span"> Léon Ghosez</span>, later joined by<span className="history-span"> Heinz Viehe</span>. Initially these symposia dealt with “small ring chemistry,” then they expanded to “small rings and activated multiple bonds.” The Louvain-la-Neuve chemists also organized in 1974 the first IUPAC symposium on Organic Synthesis, which<span className="history-span"> Albert Bruylants</span> chaired. Later, a conference on “Organic Synthesis, Present and Future” was organized by<span className="history-span"> L. Ghosez</span> in Louvain-la-Neuve.</p>
                        <p><span className="history-span">The BOSS: a platform for innovation in organic synthesis</span><br/> The success of these initial meetings led to discussions about organizing an international symposium on organic synthesis every second year at a Belgian university. This marked the start of the BOSS conferences, which gained international acclaim.</p>
                        <p>L. Ghosez proposed the name “Belgian Organic Synthesis Symposium” (BOSS). A scientific committee was established with <span className="history-span">Pierre De Clercq</span> and <span className="history-span">Gerrit L’Abbé </span>joining<span className="history-span"> L. Ghosez, Alain Krief, Jacques Nasielski, Maurits Vandewalle and Heinz Viehe.</span></p>
                        <p><span className="history-span">The birth of the Janssen Prize</span><br/> The format of the conferences included one day of lectures by a renowned scientist, followed by four days of plenary lectures and ample time for discussion. The organizing committee recommended the conference as an ideal platform to recognize an innovative chemist who is advancing the field of organic synthesis.</p>
                        <p>Dr. Paul Janssen enthusiastically supported the idea of an international committee awarding the prize. With his sponsorship and financial support from Janssen Chimica, the prize gained international recognition and became highly esteemed in the field.</p>
                        <p><span className="history-span">An international committee takes shape.</span><br/> Dr. Paul Janssen firmly believed in an international committee awarding the prize. Nobel laureate <span className="history-span"> Derek Barton </span>became the first chairman, recommending the prize be given to chemists under 50. The scientific committee, chaired by Barton, included<span className="history-span"> L. Ghosez, Marc Julia, Ulrich Schölkopf,</span> and <span className="history-span">Hans Wynberg</span>, with<span className="history-span"> Dr. Marcel Janssen</span> serving as secretary.</p>
                        <p><span className="history-span">Royal patronage and the inaugural ceremony</span><br /> Recognizing the prize’s importance for advancing research,<span className="history-span"> L. Ghosez</span> and<span className="history-span"> Maaike Van Craen</span> convinced the royal family to attend the award ceremony 1986.<span className="history-span"> Prince Albert of Belgium</span> presented the prize to<span className="history-span"> Barry Sharpless</span> from MIT. Fifteen years later, Sharpless became a Nobel laureate, validating the committee’s choice. Following Barton’s tenure, esteemed scientists<span className="history-span"> L. Ghosez, A. Krief, P. De Clercq,</span> and <span className="history-span"> Ben Feringa</span> served as committee chairs, ensuring continuity and leadership in the selection process.</p>
                        <p><span className="history-span">Impressive awardees and prestige</span><br/> The “Janssen Prize for Creativity in Organic Synthesis” boasts a remarkable <Link className="history-link" to="/winners"> list of awardees</Link> , cementing it as one of the most prestigious honors in the field. The organic chemistry community owes its gratitude to Dr. Paul Janssen and the ongoing support of the Janssen Pharmaceutical Companies of Johnson & Johnson for their commitment to advancing this field.</p>

                    </div>
                    <div className="author-block pt-5 ps-4">
                        <ul>{listItems}</ul>
                    </div>
                </section>
            </Container>
        </div>
    )
};
